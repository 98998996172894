import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.tsx';
import { API } from './api.tsx';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
if(!rootElement) {
  throw 'oh no';
}
const root = ReactDOM.createRoot(rootElement);

const saved_token = window.localStorage.getItem("auth_token") || undefined;
const api = new API(
  "https://kcal.koditoriet.se/api",
  t => {
    if (t) {
      window.localStorage.setItem("auth_token", t);
    } else {
      window.localStorage.removeItem("auth_token");
      window.location.reload();
    }
  },
  saved_token,
);
root.render(
  <React.StrictMode>
    <App api={api} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
