import { formatDate } from "./util.tsx";

export class LoginFailedException {}
export class RequestFailedException {}

export class Session {
    token: string
  
    constructor(token: string) {
        this.token = token;
    }
}

export type FoodEntry = {
    id: number,
    user_id: number,
    food_item_id: number,
    timestamp: number,
    count: number,
}

export type NewFoodEntry = {
    food_item_id: number,
    timestamp: number,
    count: number,
}

export type FoodItem = {
    id: number,
    name: string,
    kcal: number,
}

export type WeightEntry = {
    kilos: number,
    timestamp: number,
}

export type SevenDayStats = {
    avg_weight?: number,
    weight_diff?: number,
    avg_kcal?: number,
    kcal_diff?: number,
}

export class API {
    private base_url: string;
    private token?: string;
    private store_token_callback: (token?: string) => void;
  
    constructor(
        base_url: string,
        store_token_callback: (token?: string) => void = _ => {},
        token: string | undefined = undefined
    ) {
        this.base_url = base_url;
        this.token = token;
        this.store_token_callback = store_token_callback;
    }
  
    private async post(url: string, body: any, auth: boolean = true): Promise<{ok: boolean, code: number, body?: any}> {
        const init = {
            "body": JSON.stringify(body),
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
            }
        };
        if(auth) {
            init.headers["Authorization"] = `bearer ${this.token}`;
        }
        const response = await fetch(`${this.base_url}/${url}`, init);
        if (response.status == 401) {
            this.setToken(undefined);
        }
        return {
            "ok": response.ok,
            "code": response.status,
            "body": response.ok ? await response.json() : null,
        }
    }
  
    private async get(url: string, auth: boolean = true): Promise<{ok: boolean, code: number, body?: any}> {
        const init = {
            "method": "GET",
        };
        if(auth && this.token) {
            init["headers"] = {"Authorization": `bearer ${this.token}`};
        }
        const response = await fetch(`${this.base_url}/${url}`, init);
        if (response.status == 401) {
            this.setToken(undefined);
        }
        return {
            "ok": response.ok,
            "code": response.status,
            "body": response.ok ? await response.json() : null,
        }
    }
  
    async getFoodEntries(date?: Date): Promise<Array<FoodEntry>> {
        const dateParam = date? formatDate(date) : '';
        const url = `food-entries/${dateParam}`;
        const response = await this.get(url);
        if(!response.ok) {
            throw new RequestFailedException();
        }
        return response.body;
    }
  
    async getWeightEntries(date?: Date): Promise<Array<WeightEntry>> {
        const dateParam = date? formatDate(date) : '';
        const url = `weight-entries/${dateParam}`;
        const response = await this.get(url);
        if(!response.ok) {
            throw new RequestFailedException();
        }
        return response.body;
    }
  
    async getWeeklyStats(date?: Date): Promise<SevenDayStats> {
        const dateParam = date? formatDate(date) : '';
        const url = `stats/${dateParam}`;
        const response = await this.get(url);
        if(!response.ok) {
            throw new RequestFailedException();
        }
        return response.body;
    }
  
    async getFoodItems(): Promise<{[id: number]: FoodItem}> {
        const url = `food-items/`;
        const response = await this.get(url);
        if(!response.ok) {
            throw new RequestFailedException();
        }
        const foodItemDict = {};
        for(const foodItem of response.body) {
            foodItemDict[foodItem.id] = foodItem;
        }
        return foodItemDict;
    }

    async addFoodEntry(foodEntry: NewFoodEntry): Promise<FoodEntry> {
        const response = await this.post("food-entries/", foodEntry);
        if (!response.ok) {
            throw new RequestFailedException();
        }
        return response.body;
    }

    async addWeightEntry(weightEntry: WeightEntry): Promise<WeightEntry> {
        const response = await this.post("weight-entries/", weightEntry);
        if (!response.ok) {
            throw new RequestFailedException();
        }
        return response.body;
    }

    async login(username: string, password: string): Promise<Session> {
        const response = await this.post("login/", {"email": username, "password": password}, false);
        if(!response.ok) {
            throw new LoginFailedException();
        }
        this.setToken(response.body.token);
        return new Session(response.body.token);
    }

    private setToken(token?: string): void {
        this.token = token;
        (this.store_token_callback)(token);
    }

    getActiveSession(): Session | null {
        return this.token ? new Session(this.token) : null;
    }
}
