import React, { useEffect, useRef, useState } from 'react';
import { Session, API, FoodEntry, FoodItem, WeightEntry, SevenDayStats } from './api.tsx';

const Login = (props: { setSession: (_: Session) => void; api: API; }) => {
    const setSession: (_: Session) => void = props.setSession;
    const api: API = props.api;
    const [state, setState] = useState<'initial' | 'working' | 'failed'>('initial');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const passwordInput = useRef<HTMLInputElement>(null);
    const login = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setState('working');
        try {
            const session = await api.login(email, password);
            setSession(session);
        } catch(e) {
            setState('failed');
            setPassword('');
            passwordInput.current?.focus();
        }
    };
    return (
        <form className="login-form">
            <div>
                Username:
                <input
                    type="email"
                    disabled={state == 'working' || undefined}
                    value={email}
                    autoFocus={true}
                    onChange={e => setEmail(e.target.value)}
                />
            </div>
            <div>
                Password:
                <input
                    type="password"
                    disabled={state == 'working' || undefined}
                    value={password}
                    ref={passwordInput}
                    onChange={e => setPassword(e.target.value)}
                />
            </div>
            <div><button disabled={state == 'working' || undefined} onClick={login}>Login</button></div>
            {state == 'failed' ? <div className="error">Wrong username or password!</div> : ''}
        </form>
    );
};

const FoodItemInput = (props: {foodItems: Array<FoodItem>, onSelectFoodItem}) => {
    const [text, setText] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const onSelectFoodItem = async (fi: FoodItem) => {
        await (props.onSelectFoodItem)(fi);
        setText('');
        inputRef.current?.focus();
    };
    let filteredFoodItems: Array<any> = [];
    if(text.length >= 2) {
        const lowercaseMatchText = text.toLowerCase();
        filteredFoodItems = props.foodItems.filter(
            fi => fi.name.toLowerCase().includes(lowercaseMatchText)
        ).map(fi => {
            const lowerCaseName = fi.name.toLowerCase();
            const filterTextIndex = lowerCaseName.indexOf(lowercaseMatchText);
            const pre = fi.name.substring(0, filterTextIndex);
            const match = fi.name.substring(filterTextIndex, filterTextIndex + text.length);
            const post = fi.name.substring(filterTextIndex + text.length);
            return (
                <a href='javascript:void(0);'>
                <li className='food-item' onClick={e => onSelectFoodItem(fi)}>
                    <span className='name'>{pre}<span className='text-match'>{match}</span>{post}</span>
                    <span className='extra'>({fi.kcal} kcal)</span>
                </li>
                </a>
            );
        });
    }
    return (
        <div className='food-item-input'>
            <ul className='food-item-list'>
                {filteredFoodItems}
            </ul>
            <input
                placeholder='Lägg till mål'
                type='text'
                value={text}
                ref={inputRef}
                onChange={e => setText(e.target.value)}
            />
        </div>
    )
};

const FoodEntryList = ({foodItems, foodEntries}) => {
    const kcal = foodEntries.map(fe => fe.count * foodItems[fe.food_item_id].kcal).reduce((a, x) => a + x, 0);
    return (
        <div className='food-entry-list'>
            <h2>Tidigare mål <span className='extra'>({kcal} kcal)</span></h2>
            <ul>
                {foodEntries.map(
                    fe => {
                        const foodItem = foodItems[fe.food_item_id];
                        const kcal = fe.count * foodItem.kcal;
                        const date = new Date(fe.timestamp*1000);
                        const hourStr = date.getHours() < 10
                            ? ('0' + date.getHours())
                            : date.getHours().toString();
                        const minuteStr = date.getMinutes() < 10
                            ? ('0' + date.getMinutes())
                            : date.getMinutes().toString();
                        const timeStr = `${hourStr}:${minuteStr}`;
                        return (<li className='food-entry'>
                            <span className='timestamp'>{timeStr}</span>
                            <span className='name'>{foodItem.name}</span>
                            <span className='extra'>({fe.count} st., {kcal} kcal)</span>
                        </li>)
                    }
                )}
            </ul>
        </div>
    )
};

const SevenDayStatsWidget = (
    props: {
        weight?: number,
        weight_diff?: number,
        kcal?: number,
        kcal_diff?: number,
    }
) => {
    return (
        <ul className="seven-day-stats">
            {props.weight ? <li className='label'>Seven day avg.</li> : ""}
            {props.weight_diff ? <li className='label'>Weekly diff</li> : ""}
            {props.weight ? <li>{props.weight} kg</li> : ""}
            {props.weight_diff ? <li>{props.weight_diff} kg</li> : ""}
            {props.kcal ? <li className='label'>Seven day avg.</li> : ""}
            {props.kcal_diff ? <li className='label'>Weekly diff</li> : ""}
            {props.kcal ? <li>{props.kcal} kcal</li> : ""}
            {props.kcal_diff ? <li>{props.kcal_diff} kcal</li> : ""}
        </ul>
    )
};

const Dashboard = (props: { api: API; }) => {
    const [weightAlreadyEntered, setWeightAlreadyEntered] = useState<boolean>(true);
    const [showWeightEntryDialog, setShowWeightEntryDialog] = useState<boolean>(false);
    const [stats, setStats] = useState<SevenDayStats | undefined>(undefined);
    const [foodEntries, setFoodEntries] = useState<Array<FoodEntry>>([]);
    const [foodItems, setFoodItems] = useState<{dict: {[id: number]: FoodItem}, array: Array<FoodItem>}>(
        {dict: {}, array: []}
    );
    const updateWeight = async kilos => {
        setShowWeightEntryDialog(false);
        setWeightAlreadyEntered(true);
        const weightEntry = {
            "kilos": kilos,
            "timestamp": Math.floor(new Date().getTime() / 1000),
        };
        await props.api.addWeightEntry(weightEntry);
        const stats = await props.api.getWeeklyStats(new Date());
        setStats(stats);
    };
    const addFoodEntry = async (foodItem: FoodItem) => {
        const newFoodEntry = {
            food_item_id: foodItem.id,
            count: 1,
            timestamp: Math.floor(new Date().getTime() / 1000),
        };
        const foodEntry = await props.api.addFoodEntry(newFoodEntry);
        setFoodEntries(foodEntries => [...foodEntries, foodEntry]);
    };
    useEffect(
        () => {(async () => {
            const foodItems = await props.api.getFoodItems();
            const foodItemList: Array<FoodItem> = [];
            for(const id in foodItems) {
                foodItemList.push(foodItems[id]);
            }
            const today = new Date();
            const foodEntries = await props.api.getFoodEntries(today);
            const todays_weight_entries = await props.api.getWeightEntries(today);
            if (!todays_weight_entries.length) {
                setWeightAlreadyEntered(false);
            }
            const stats = await props.api.getWeeklyStats(today);
            setStats(stats);
            setFoodItems({dict: foodItems, array: foodItemList});
            setFoodEntries(foodEntries);
        })()},
        [props.api]
    )
    return (
        <div className='dashboard'>
            {weightAlreadyEntered ? "" : showWeightEntryDialog
                ? <WeightInput
                    api={props.api}
                    onCancel={() => setShowWeightEntryDialog(false)}
                    onSubmit={updateWeight}
                />
                : <button className="extra" onClick={() => setShowWeightEntryDialog(true)}>
                    Registrera vikt
                </button>
            }
            <FoodEntryList foodItems={foodItems.dict} foodEntries={foodEntries} />
            <SevenDayStatsWidget
                weight={stats?.avg_weight}
                weight_diff={stats?.weight_diff}
                kcal={stats?.avg_kcal}
                kcal_diff={stats?.kcal_diff}
            />
            <FoodItemInput foodItems={foodItems.array} onSelectFoodItem={addFoodEntry} />
        </div>
    );
};

const WeightInput = (props: { api: API; onSubmit: (weight: number) => void; onCancel: () => void; }) => {
    const [kilos, setKilos] = useState<string | undefined>(undefined);
    const submit = () => {
        if (!kilos) {
            (props.onCancel)();
        } else {
            (props.onSubmit)(Number(kilos));
        }
    };
    return (
        <form className="weight-input" onSubmit={submit}>
            <input
                type="number"
                step=".01"
                placeholder="Vikt i kilo"
                autoFocus={true}
                value={kilos}
                onChange={e => setKilos(e.target.value)}
            />
            <button>Registrera vikt</button>
        </form>
    );
};

const App = (props: { api: API; }) => {
    const [session, setSession] = useState<Session | null>(props.api.getActiveSession());
    return (
        <div className="App">
            {session ? <Dashboard api={props.api} /> : <Login setSession={setSession} api={props.api}/>}
        </div>
    );
};

export default App;
