export const pad = (str: string, pad_char: string, target_length: number) => {
    const num_chars = target_length - str.length;
    let chars = '';
    for(let i = 0; i < num_chars; i++) {
        chars += pad_char;
    }
    return chars + str;
}

export const formatDate = (date: Date) => {
    const year = date.getUTCFullYear();
    const month = pad((date.getUTCMonth() + 1).toString(), '0', 2);
    const day = pad(date.getUTCDate().toString(), '0', 2);
    return `${year}-${month}-${day}`;
}